















import { orderBy, uniqBy, omit } from 'lodash'
import { Component, Prop, Mixins, Watch } from 'vue-property-decorator'

import ChatView from '@/components/views/chat/ChatView.vue'
import NotifyMixin from '@/mixins/NotifyMixin'
import MasterExercisesModule from '@/store/modules/master/exercises'
import {
  EducationLargeExerciseResource,
  EducationLargeTaskResource,
  EducationMasterGroupResource, ExerciseStatus,
  MessageResource,
  MessageStore, TaskType,
} from '@/store/types'

const camelCaseKeys = require('camelcase-keys')

@Component({
  components: {
    ChatView,
  },
})
export default class ExercisesItemTaskMessages extends Mixins(NotifyMixin) {
  @Prop({ required: true })
  private masterGroup!: EducationMasterGroupResource

  @Prop({ required: true })
  private exercise!: EducationLargeExerciseResource

  @Prop({ required: true })
  private task!: EducationLargeTaskResource

  private get note() {
    if (this.isStatusWait) {
      return this.exercise.autoCheck ? 'Задание предполагает самостоятельную проверку мастером' : 'Чтобы начать общение с наставником, необходимо выполнить домашнее задание.'
    } else if ((this.isStatusChecked || this.isStatusOnCheck) && this.exercise.autoCheck) {
      return 'Задание предполагает самостоятельную проверку мастером'
    }
    return 'Проверка домашнего задания завершена наставником. Чат закрыт'
  }

  private get messages () {
    return orderBy(uniqBy(MasterExercisesModule.messages, 'id'), ['createdAt'])
  }

  private get isPractice () {
    return this.task.type.value === TaskType.PRACTICE
  }

  private get isTest () {
    return this.task.type.value === TaskType.TEST
  }

  private get isCreative() {
    return this.task.type.value === TaskType.CREATIVE
  }

  private get isStatusWait () {
    return this.task.status.value === ExerciseStatus.WAIT || this.task.status.value === ExerciseStatus.IN_PROCESS
  }

  private get isStatusChecked () {
    return this.task.status.value === ExerciseStatus.CHECKED
  }

  private get isStatusComplete () {
    return this.task.status.value === ExerciseStatus.COMPLETE
  }

  private get isStatusOnCheck () {
    return this.task.status.value === ExerciseStatus.ON_CHECK
  }

  private get isMistakesCompleted() {
    return MasterExercisesModule.mistakes.every(mistake => mistake.completed)
  }

  private get enabled () {
    if (this.isStatusComplete) {
      return true
    } else if (this.isStatusOnCheck) {
      return true
    } else if (this.isStatusChecked && this.isTest) {
      return true
    } else if (this.isStatusChecked || this.isStatusOnCheck && this.isCreative) {
      return true
    }
    return this.isStatusChecked
  }

  private sub: any = null
  private subMessage: any = null

  private mounted () {
    if (this.task.dialogInitialized || (this.isTest && this.isStatusChecked || this.isStatusComplete)) {
      this.fetchMessages()
    }

    if (this.enabled) {
      this.socketSubscribe()
    }
  }

  private socketSubscribe() {
    this.sub = this.$centrifuge.newSubscription(`exercise.messages.${this.task.formId}`)
    this.sub.on('publication', (ctx: any) => {
      if (ctx.data?.event === 'Education\\Exercise\\NewMessageEvent') {
        const response = omit(ctx.data, ['event', 'socket']) as MessageResource
        MasterExercisesModule.addTaskMessage(camelCaseKeys(response, { deep: true }))
        this.$bus.$emit('clearTextarea', response)
      }
    })
    this.sub.subscribe()
  }

  private destroyed() {
    if (this.sub) {
      this.sub.unsubscribe()
      this.$centrifuge.removeSubscription(this.sub)
    }
    if (this.subMessage) {
      this.subMessage.unsubscribe()
      this.$centrifuge.removeSubscription(this.subMessage)
    }
  }

  private fetchMessages () {
    MasterExercisesModule.fetchTaskMessages({
      exerciseUUID: this.exercise.uuid,
      masterGroupID: this.masterGroup.id,
      taskUUID: this.task.uuid,
    })
      .catch(this.notifyError)
  }

  private handleSendMessage (form: MessageStore) {
    MasterExercisesModule.sendTaskMessage({
      exerciseUUID: this.exercise.uuid,
      masterGroupID: this.masterGroup.id,
      params: form,
      taskUUID: this.task.uuid,
    })
      .catch(this.notifyError)
  }

  @Watch('task')
  private watchTask() {
    MasterExercisesModule.clearTaskMessages()
    if (this.sub) {
      this.sub.unsubscribe()
      this.$centrifuge.removeSubscription(this.sub)
    }

    if (this.task.dialogInitialized || (this.isTest && this.isStatusChecked || this.isStatusComplete)) {
      this.fetchMessages()
    }

    if (this.enabled) {
      this.socketSubscribe()
    }
  }
}
